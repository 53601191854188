@import "./../../../_styles/variables";
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: unset;
  @media only screen and (min-width: 600px) {
    height: 400px;
  }
  width: 100%;
  h2 {
    margin-top: 0;
    border-bottom: 2px solid $botticelli;
    width: 100%;
    padding-bottom: 15px;
    text-align: center;
    margin-bottom: 10px;
  }
  .info {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    @media only screen and (min-width: 600px) {
      flex-direction: row;
    }
    .restaurantInfo,
    .invoiceInfo {
      p {
        &:first-child {
          font-weight: bold;
          font-size: 1.3em;
        }
      }
    }
    .invoiceInfo {
      p {
        text-align: right;
      }
    }
  }
  .checkbox {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    gap: 10px;
    @media only screen and (min-width: 600px) {
      flex-direction: row;
    }
    label {
      margin-bottom: 0;
    }
  }
  .manually {
    p {
      margin-top: 35px;
    }
  }

  .manually,
  .viaStripe {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    height: unset;
    @media only screen and (min-width: 600px) {
      height: 145px;
    }
    p {
      font-size: 1.2em;
    }
    button {
      margin-bottom: 0;
    }
  }
  .viaStripe {
    .cardError {
      color: $terracotta;
    }
  }
}
