@import "./../../../_styles/variables";

.list {
  margin-top: 0;
  overflow-x: auto;
  max-height: 700px;
  min-height: 200px;

  @media only screen and (min-width: 992px) {
    max-height: unset;
    height: 100%;
  }

  background-color: $wild-sand;

  &::-webkit-scrollbar {
    width: 7px;
    height: 7px;
    padding: 5px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 7px;
    --webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    background: $casper;
  }

  .header,
  .headerCharged {
    position: sticky;
    top: 0;
  }

  .block,
  .header {
    min-width: 830px;
    display: grid;
    grid-template-columns: 1.5fr 1fr 1fr 1.5fr 1fr 180px 50px;
    align-items: center;
    padding-left: 10px;
    height: 60px;
    color: $mine-shaft;
    font-size: 12px;
    background-color: $wild-sand;
    border-bottom: 1px solid $mercury;

    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-right: 15px;
    }

    .blockUser {
      padding: 10px;
      font-size: 14px;
      min-width: 155px;
    }

    .blockLocation {
      min-width: 120px;
    }

    .blockContainer {
      min-width: 100px;
    }

    .blockOverdue {
      min-width: 110px;
      text-align: center;
    }

    .blockButtons {
      padding: 10px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      width: 180px;

      button {
        width: 86px;
        height: 32px;
        margin-bottom: 0;
      }
    }

    .blockStatus {
      padding: 0 10px;
      min-width: 43px;
    }

    .resolveHidden {
      visibility: hidden;
    }
  }
  .blockCharged,
  .headerCharged {
    @extend .block;
    grid-template-columns: 1.5fr 1fr 1fr 1fr 180px 180px 50px;
  }
  .header,
  .headerCharged {
    .blockUser {
      font-size: 1.3em;
    }

    p {
      font-weight: 600;
      font-size: 1.3em;
    }
  }
  .loader {
    margin-top: 100px;
  }
}

.noContainersMsg {
  text-align: center;
}
