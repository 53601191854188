@import "./../../../_styles/variables";

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 20px;

  .header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 20px;

    @media only screen and (min-width: 992px) and (max-width: 1110px) {
      height: 200px;
    }

    h2 {
      margin: 0px;
    }

    h3 {
      margin: 0 0 10px;
    }
  }

  .pagination {
    padding-top: 15px;
    margin-top: auto;
  }
}
