@import "./../../../_styles/variables";

.wrapper {
  background-color: $white;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media only screen and (min-width: 500px) {
    align-items: center;
  }

  p {
    margin-bottom: 20px;
  }

  .tableWrapper {
    margin-bottom: 30px;
    overflow-x: auto;

    &::-webkit-scrollbar {
      width: 7px;
      height: 7px;
      padding: 5px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 7px;
      --webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
      background: $casper;
    }

    table {
      border-collapse: collapse;

      thead th {
        color: $gray;
        font-size: 0.875rem;
        font-weight: normal;
        border-top: 1px solid transparentize($gray, 0.7);
        border-bottom: 1px solid transparentize($gray, 0.7);
        padding: 8px 24px;

        &:nth-of-type(even) {
          border: 1px solid transparentize($gray, 0.7);
        }
      }

      tbody {
        tr {
          td {
            text-align: center;
            color: $mine-shaft;
            border-top: 1px solid transparentize($gray, 0.7);
            border-bottom: 1px solid transparentize($gray, 0.7);
            padding: 8px 24px;
            max-width: 200px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            &:first-of-type {
              text-align: left;
            }

            &:nth-of-type(even) {
              border: 1px solid transparentize($gray, 0.7);
            }
          }
        }
      }
    }
  }

  .input {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
    align-items: center;

    .selector {
      width: 80px;
      margin-top: 30px;

      @media only screen and (min-width: 600px) {
        margin-top: 0;
      }
    }
  }

  .returnSelector {
    width: 100%;
  }

  .loading {
    div {
      padding: 25px;
    }
  }
}
