@import "../../../_styles/variables";

.wrapper {
  padding: 20px;
  padding-top: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 500px;

  header {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    min-width: 310px;

    @media only screen and (min-width: 470px) {
      flex-direction: row;
      align-items: center;
      margin-bottom: 0;

      .checkboxWrapper {
        margin-left: 20px;
      }
    }

    .checkboxWrapper {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      label {
        margin-bottom: 0;
      }
    }
  }

  .list {
    height: 600px;
    overflow-x: auto;
    margin-top: 20px;

    &::-webkit-scrollbar {
      width: 7px;
      height: 7px;
      padding: 5px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 7px;
      --webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
      background: $casper;
    }

    .block {
      display: grid;
      grid-template-columns: 1fr 151px;
      justify-content: flex-start;
      align-items: center;
      padding-left: 10px;
      height: 60px;
      color: $mine-shaft;
      font-size: 12px;
      background-color: $wild-sand;
      border-bottom: 1px solid $mercury;
      min-width: 310px;

      .blockId {
        padding: 10px;
      }

      .blockUser {
        display: flex;
        flex-direction: row;
        align-items: center;
        min-width: 150px;

        p {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .blockButton {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding-right: 45px;
        padding-left: 20px;

        button {
          width: 86px;
          height: 32px;
          margin-bottom: 0;
        }
      }
    }
  }

  @media only screen and (min-width: 600px) {
    .searchOptions {
      flex-direction: row;
      justify-content: space-between;

      .selectGroup {
        justify-content: space-between;
        width: 50%;

        .select {
          width: 45%;
        }
      }
    }
  }

  @media only screen and (min-width: 992px) {
    .list {
      height: unset;
    }
  }
}

.loading {
  margin-top: 30px;
}

.pagination {
  padding-top: 10px;
  margin-top: auto;
}
