@import "../../../_styles/variables";
@import "../../../_styles/mixins";

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  .options {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    padding: 10px 0;
    width: 100%;

    @media only screen and (min-width: 600px) {
      flex-direction: row;
    }
    .selectDate {
      width: 120px;
    }
    .back {
      button {
        margin-bottom: 0;
        width: max-content;
        max-width: 100%;
      }
    }
  }
  h4 {
    margin: 0;
    padding-left: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: $lighterGray;
    border-bottom: 1px solid $mercury;
  }
  .table {
    margin-top: 0;
    overflow-x: auto;
    max-height: 400px;
    min-height: 200px;
    @media only screen and (min-width: 992px) {
      max-height: unset;
      height: 100%;
    }
    .header {
      position: sticky;
      top: 0;
    }

    .block,
    .header {
      min-width: 800px;
      display: grid;
      grid-template-columns: 2fr 1fr 2fr 1fr;
      align-items: center;
      padding-left: 10px;
      padding-right: 10px;

      height: 60px;
      color: $mine-shaft;
      font-size: 12px;
      border-right: 1px solid $wild-sand;
      border-left: 1px solid $wild-sand;
      border-bottom: 1px solid $mercury;

      p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-right: 15px;
        @include noUserSelect;
      }

      .blockUser {
        padding: 10px;
        font-size: 14px;
        min-width: 155px;
      }

      .blockButton {
        button {
          width: 100px;
          margin: 0;
        }
      }
      .blockStatus {
        .blockMsg {
          font-size: 1rem;
          padding-left: 22px;
        }

        button {
          width: 100px;
          margin: 0;
        }
      }
    }
    .header {
      background-color: $lighterGray;
      .blockStatus {
        padding-left: 22px;
      }
      .blockUser {
        font-size: 1.3em;
      }

      p {
        font-weight: 600;
        font-size: 1.3em;
      }
    }
    .loader {
      margin-top: 100px;
    }
  }
  .pagination {
    padding-top: 15px;
    margin-top: auto;
  }
  .noTransactionsMsg {
    text-align: center;
    padding: 100px 0 100px 0;

    span {
      font-size: 1.188rem;
      color: $link-default;
      font-family: $font-primary-bold;
    }
  }
}
