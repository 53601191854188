@import "../../../_styles/variables";

.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    border-radius: 4px;
    padding: 20px;

    .transaction {
        margin-bottom: 20px;
    }

    .form {
        display: flex;
        flex-direction: column;

        .formContent {
            display: flex;
            flex-direction: row;
        }

        .submit {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 10px;
        }
    }

    .previousRefunds {
        display: flex;
        flex-direction: column;
        width: 240px;

        h3 {
            text-align: center;
        }

        table {

            thead,
            tbody {
                tr {

                    th,
                    td {
                        cursor: default;

                        &:first-child {
                            padding-left: 10px;
                        }

                        &:last-child {
                            text-align: right;
                            padding-right: 10px;
                        }
                    }

                }
            }
        }
    }

    @media only screen and (min-width: 600px) {
        padding: 30px 50px;

        .form {
            flex-direction: row;
            gap: 20px;

            .formContent {
                .amount {
                    width: 180px;
                }

                .select {
                    margin-top: -20px;
                }
            }

            .submit {
                flex-direction: row;
                justify-content: flex-end;

                button {
                    margin-bottom: 0;
                }
            }
        }

        .previousRefunds {
            width: 300px;
        }
    }
}

.error {
    color: $terracotta;
}