@import "../../../_styles/variables";
@import "../../../_styles/mixins";

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;

  .filter {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
    padding: 10px 0;
    width: 100%;

    @media only screen and (min-width: 600px) {
      flex-direction: row;
    }

    .searchBar {
      width: 100%;
      div {
        max-width: unset;
      }
    }
  }
  .restaurants {
    margin-top: 0;
    overflow-x: auto;
    max-height: 400px;
    min-height: 200px;
    @media only screen and (min-width: 992px) {
      max-height: unset;
      height: 100%;
    }
    .header {
      position: sticky;
      top: 0;
    }

    .block,
    .header {
      min-width: 450px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding-left: 10px;
      padding-right: 10px;

      height: 60px;
      color: $mine-shaft;
      font-size: 12px;
      border-right: 1px solid $wild-sand;
      border-left: 1px solid $wild-sand;
      border-bottom: 1px solid $mercury;

      p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-right: 15px;
        @include noUserSelect;
      }

      .blockUser {
        padding: 10px;
        font-size: 14px;
        min-width: 155px;
      }

      .blockButton {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        button {
          width: 100px;
          margin: 0;
        }
      }
    }

    .header {
      background-color: $lighterGray;

      .blockUser {
        font-size: 1.3em;
      }

      p {
        font-weight: 600;
        font-size: 1.3em;
      }
    }
    .loader {
      margin-top: 100px;
    }
  }
  .pagination {
    padding-top: 15px;
    margin-top: auto;
  }
}
